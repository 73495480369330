import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import './Header.css';

const Header = () => (
  <header>
    <div className="logo-container">
      <Link to="/" href="/" className="logo">
        <span className="initials">RA</span>
      </Link>
      <Link to="/" href="/">
        <h1 className="name">RAY AFFAS</h1>
      </Link>
    </div>
    <NavBar />
  </header>
);

export default Header;
