import React from 'react';
import './About.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const About = () => (
  <div>
    <Header />
    <main>
      <div className="main-container">
        <h1 className="page-heading">About</h1>
        <div>
          <p>
            Hi, I{"'"}m Ragheed Fathullah Affas, you can call me{' '}
            <span className="strong">Ray!</span>
          </p>
          <p />
          <p>
            I{"'"}m a frontend developer who always challenges himself to learn
            new exciting technologies. I enjoy diving into software dilemmas and
            exploring different resources to find solutions. Nothing feels more
            satisfying for me than seeing the results of my work popping up on
            the screen.
          </p>
          <p>
            I also enjoy sports, especially soccer and tennis (I{"'"}m a big fan
            of Barcelona FC and Roger Ferderer respectively). I{"'"}m inspired
            by a hot cup of Timmy{"'"}s French Vanilla, excellent music, and
            most importantly by the love of my family.
          </p>
          <p>
            Please{' '}
            <a className="link-style" href="/contact">
              contact me
            </a>{' '}
            if you would like to work on a project together, or just want to
            chat.
          </p>
        </div>
        <div className="resume-container">
          <div className="dots">
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </div>
          <div className="resume-inside">
            <h3>/* --Resume-- */</h3>
            <h4>/* SUMMARY OF QUALIFICATIONS */</h4>
            <ul>
              <li className="moveUp">
                Programming skills - JavaScript (ES6), TypeScript, ReactJS,
                HTML, CSS, Java
              </li>
              <li className="moveUp">
                Excellent organization, self-motivation, and problem-solving
                skills
              </li>
              <li className="moveUp">
                Creative, strong communication skills and consistently meets
                deadlines
              </li>
              <li className="moveUp">
                Passionate about web development and new technologies
              </li>
            </ul>
            <h4>/* EDUCATION */</h4>
            <ul>
              <li className="moveUp">
                Web Development 10-week Intensive Course, Codecademy - Jan 30,
                2018{' '}
                <a
                  href="https://www.credential.net/gd24etxl"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-style-2"
                >
                  (view credential)
                </a>
              </li>
              <li className="moveUp">
                Web Applications - Front-End Development 8-week Intensive
                Course, Codecademy - Jan 18, 2018{' '}
                <a
                  href="https://www.credential.net/weju70bm"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-style-2"
                >
                  (view credential)
                </a>
              </li>
              <li className="moveUp">
                Oracle Certified Associate, Java SE 8 Programmer - Jan 31, 2017{' '}
                <a
                  href="https://www.youracclaim.com/badges/78f591ec-0cfe-403c-a96a-ba01687f0770/public_url"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-style-2"
                >
                  (view certificate)
                </a>
              </li>
              <li className="moveUp">
                Engineering Connections Bridging Program, Access Employment –
                Mississauga, Canada - Feb 2016
              </li>
              <li className="moveUp">
                Bachelors degree in Communication Engineering at the University
                of Mosul - 2008
              </li>
            </ul>
            <h4>/* Experience */</h4>
            <h5>
              // Frontend Developer, Travelcoup Systems Inc. - Etobicoke, ON,
              Canada
            </h5>
            <span className="time">// Apr 2018 – Present</span>
            <ul>
              <li className="moveUp">
                built web-based applications using Reactjs, Redux, JavaScript,
                TypeScript, HTML and CSS.
              </li>
              <li className="moveUp">
                Participated in the entire software development lifecycle,
                focusing on coding and debugging
              </li>
              <li className="moveUp">
                Supported implementation of the target picture of the Travelcoup
                IT architecture
              </li>
              <li className="moveUp">
                Wrote clean code to develop functional applications and services
              </li>
              <li className="moveUp">
                Troubleshoot and debug applications and services
              </li>
              <li className="moveUp">Wrote and performed tests</li>
              <li className="moveUp">
                Managed cutting-edge technologies to improve legacy applications
              </li>
              <li className="moveUp">
                Collaborated with back-end developers to integrate server-side
                logics
              </li>
              <li className="moveUp">
                Gathered and addressed technical and design requirements
              </li>
              <li className="moveUp">
                Built reusable code and libraries for future use
              </li>
              <li className="moveUp">
                Liaised with other developers, designers, management, and system
                administrators to identify new features and plan and implement
                those
              </li>
              <li className="moveUp">Follow relevant emerging technologies</li>
            </ul>
            <h5>// Freelancing and Side Projects</h5>
            <span className="time">// Sep 2017 – Apr 2018</span>
            <ul>
              <li className="moveUp">
                Teaching Assistant for Java online course on udemy.com
              </li>
              <li className="moveUp">
                Designed and built my portfolio website www.rayaffas.ca using
                Reactjs, HTML, JSX, CSS
              </li>
              <li className="moveUp">
                Built “Jammming” web app, a React based app that allows users to
                search the Spotify library, create a custom playlist, then save
                it to their Spotify account (a capstone project for Codecademy
                Front-End Development Intensive course)
              </li>
              <li className="moveUp">
                Built “Colmar Academy” landing page according to design
                wireframes (a capstone project for Codecademy Web Development
                Intensive course)
              </li>
            </ul>
            <h5>// E-commerce Platform Specialist, Elryan Group - Online</h5>
            <span className="time">// Nov 2012 - Dec 2017</span>
            <ul>
              <li className="moveUp">
                Created company’s webstore (elryan.com) – Magento Platform
              </li>
              <li className="moveUp">
                Developed customer service online SMS solution that is
                integrated with{' '}
                <a
                  href="https://www.nexmo.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-style-2"
                >
                  Nexmo
                </a>{' '}
                API. Built with JavaScript, HTML, CSS, Bootstrap and PHP
              </li>
              <li className="moveUp">
                Hired software development agencies to perform industry-specific
                solutions
              </li>
              <li className="moveUp">
                Worked with development teams to develop e-commerce hybrid
                mobile app
              </li>
              <li className="moveUp">
                Cooperated with web developers to integrate the appropriate
                solutions according to business needs
              </li>
              <li className="moveUp">
                Fixed bugs and troubleshot technical issues escalated by system
                users, customers and management
              </li>
              <li className="moveUp">
                Updated and installed new software versions and patches
              </li>
              <li className="moveUp">
                Implemented sales plans and promotions via Magento backend
              </li>
            </ul>
            <h5>// Sr. Core Engineer, Korek Telecom - Erbil</h5>
            <span className="time">// Feb 2012 - Nov 2102</span>
            <ul>
              <li className="moveUp">
                Programmed and configured internal and external
                telecommunications links
              </li>
              <li className="moveUp">
                Solved and troubleshot trouble tickets reported by monitoring
                team
              </li>
              <li className="moveUp">
                Supervised maintenance-window activities to test and approve
                system upgrades, backups and maintenance procedures performed by
                third-party teams
              </li>
            </ul>
            <h5>
              // First Line Maintenance (FLM) Engineer - Nokia Siemens Networks,
              Erbil
            </h5>
            <span className="time">// 2011 - Feb 2012</span>
            <ul>
              <li className="moveUp">
                Analyzed network problems and provided solutions to operational
                issues
              </li>
              <li className="moveUp">
                Performed acceptance test procedures (ATP) of newly integrated
                sites
              </li>
              <li className="moveUp">
                Configured, installed and performed preventive checks on network
                elements
              </li>
              <li className="moveUp">
                Solved and troubleshot trouble tickets reported by monitoring
                team
              </li>
              <li className="moveUp">
                Solved network problems according to service level agreements
                (SLA)
              </li>
              <li className="moveUp">
                Documented incident reports and final solutions for problems
              </li>
              <li className="moveUp">
                Provided support to other FLM team staff
              </li>
            </ul>
            <h5>// NSS Back Office Engineer, Korek Telecom - Erbil</h5>
            <span className="time">// 2010 - Feb 2011</span>
            <ul>
              <li className="moveUp">
                Performed Monitoring, analysis and suggested improvements for
                daily/weekly/monthly KPIs
              </li>
              <li className="moveUp">
                Monitored alarms stats and solved trouble tickets
              </li>
              <li className="moveUp">
                Identified and escalated system problems to respective system
                support team
              </li>
              <li className="moveUp">
                Prepared and loaded data transcripts to network switches
              </li>
              <li className="moveUp">
                Added/deleted/modified roaming configurations
              </li>
            </ul>
            <h4>/* Other Work Experience */</h4>
            <h5>Product Recovery Technician, SCI - Mississauga, Canada</h5>
            <span className="time">// Oct 2016 – Present</span>
            <ul>
              <li className="moveUp">
                Tested, Troubleshot and recertified Xerox products.
              </li>
              <li className="moveUp">
                Coordinated with the management, client and team members to
                organize workflow and to discuss business requirements
              </li>
              <li className="moveUp">
                Attended to clients’ requests via email and phone calls and made
                sure all requests were appropriately fulfilled
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
);

export default About;
