import React from 'react';
import './Contact.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Contact = () => (
  <div>
    <Header />
    <main>
      <div className="main-container">
        <h1 className="page-heading">Contact</h1>
        <p className="contact-paragragh">
          Feel free to contact me throught the email below, I{"'"}d love to hear
          from you.
        </p>
        <p className="contact-paragragh">
          <a className="link-style" href="mailto:ray.affas@gmail.com">
            ray.affas@gmail.com
          </a>
        </p>
      </div>
    </main>
    <Footer />
  </div>
);

export default Contact;
