import React from 'react';
import './Projects.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Projects = () => (
  <div>
    <Header />
    <main>
      <div className="main-container">
        <h1 className="page-heading">Projects</h1>
        <div>
          <ul className="projects-list">
            <li>
              <h3 className="project-title">
                <a
                  className="link-style-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.travelcoup.com"
                >
                  travelcoup.com
                </a>
              </h3>
              <p>
                The main product I proudly work on as a part of my job as FE
                developer at Travelcoup Systems Inc.<br />
                Built with: React, Redux and TypeScript.
              </p>
            </li>
            <li>
              <h3 className="project-title">
                <a
                  className="link-style-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/"
                >
                  rayaffas.ca
                </a>
              </h3>
              <p>
                My portfolio website<br />Built with: React and hosted on
                <a
                  className="link-style-2"
                  href="https://www.netlify.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >{' '}
                  Netlify
                </a>
              </p>
            </li>
            <li>
              <h3 className="project-title">
                <a
                  className="link-style-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.elryan.com/en/?___SID=U"
                >
                  elryan.com
                </a>
              </h3>
              <p>
                Built for: Elryan Group<br />Built with: Magento<br />Link:{' '}
                <a
                  className="link-style-5 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.elryan.com/en/?___SID=U"
                >
                  elryan.com
                </a>
              </p>
            </li>
            <li>
              <h3 className="project-title">
                <a
                  className="link-style-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/rayaffas/sms-nexmo"
                >
                  Customer Service SMS Solution
                </a>
              </h3>
              <p>
                Built for: Elryan Group<br />Built with: PHP, JavaScript, HTML
                and Bootstrap<br />Link:{' '}
                <a
                  className="link-style-5 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/rayaffas/sms-nexmo"
                >
                  github repo
                </a>
              </p>
            </li>
            <li>
              <h1>Some learning projects:</h1>
            </li>
            <li>
              <h3 className="project-title">
                <a
                  className="link-style-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/rayaffas/Jammming"
                >
                  Jammming
                </a>
              </h3>
              <p>
                A website that allows users to search the Spotify library,
                create a custom playlist, then save it to their Spotify account<br />Built
                With: React<br />Link1:{' '}
                <a
                  className="link-style-5 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/rayaffas/Jammming"
                >
                  github repo
                </a>
                <br />Link2:{' '}
                <a
                  className="link-style-5 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://ray_jammming.surge.sh/"
                >
                  Jammming app
                </a>
              </p>
            </li>
            <li>
              <h3 className="project-title">
                <a
                  className="link-style-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/rayaffas/ravenous-reactjs"
                >
                  Ravenous
                </a>
              </h3>
              <p>
                A Yelp-like clone
                <br />Built with: React<br />Link:{' '}
                <a
                  className="link-style-5 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/rayaffas/ravenous-reactjs"
                >
                  github repo
                </a>
              </p>
            </li>
            <li>
              <h3 className="project-title">
                <a
                  className="link-style-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://rayaffas.github.io/Colmar-Academy/"
                >
                  Colmar Academy
                </a>
              </h3>
              <p>
                A landing page for the fictional Colmar Academy
                <br />Built with: HTML and CSS<br />Link1:{' '}
                <a
                  className="link-style-5 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://rayaffas.github.io/Colmar-Academy/"
                >
                  website
                </a>
                <br />Link2:{' '}
                <a
                  className="link-style-5 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/rayaffas/Colmar-Academy"
                >
                  github repo
                </a>
              </p>
            </li>
            <li>
              <h3 className="project-title">
                <a
                  className="link-style-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/rayaffas/Minesweeper"
                >
                  Minesweeper
                </a>
              </h3>
              <p>
                The traditional Minesweeper game that can be played in the CLI
                <br />Built with: JavaScript, Babel transpiler<br />Link:{' '}
                <a
                  className="link-style-5 underline underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/rayaffas/Minesweeper"
                >
                  github repo
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </main>
    <Footer />
  </div>
);

export default Projects;
