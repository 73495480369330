import React from 'react';
// import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import './Home.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Home = () => (
  <div>
    <Header />
    <main>
      <div className="main-container">
        <h1 id="ray">I am Ray</h1>
        <p>
          A frontend developer, with a <span className="em">can-do </span>
          attitude.
        </p>
      </div>
    </main>
    <Footer />
  </div>
);

export default Home;
