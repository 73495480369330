import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

const NavBar = () => (
  <nav className="navigation">
    <ul>
      <li>
        <Link to="/about" href="/about" className="underline">
          ABOUT
        </Link>
      </li>
      <li>
        <Link to="/projects" href="/projects" className="underline">
          PROJECTS
        </Link>
      </li>
      <li>
        <Link to="/contact" href="/contact" className="underline">
          CONTACT
        </Link>
      </li>
    </ul>
  </nav>
);

export default NavBar;
