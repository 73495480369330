import React from 'react';
import './Footer.css';
import NavBar from '../NavBar/NavBar';

const Footer = () => (
  <footer>
    <h1>Where to find me</h1>
    <ul className="footer-links">
      <li className="moveUp">
        <a
          href="https://github.com/rayaffas"
          className="button"
          id="github"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>
      </li>
      <li className="moveUp">
        <a
          href="https://linkedin.com/in/rayaffas"
          className="button"
          id="linkedin"
          rel="noopener noreferrer"
          target="_blank"
        >
          LinkedIn
        </a>
      </li>
      <li className="moveUp">
        <a
          href="https://twitter.com/RagheedHA"
          className="button"
          id="twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
      </li>
      {/* <li className="moveUp">
        <a href="#/" className="button" id="upwork" target="_blank">
          Upwork
        </a>
      </li> */}
    </ul>
    <div className="bottom-links">
      <NavBar />
    </div>
  </footer>
);

export default Footer;
